import React from "react";
import {WithSnackbarProps} from "notistack";
import {
    DMSNodeState,
    DMSRESTApiClient,
    DMSWSClient,
    IDBNode,
    IDBUser, IDBUserGroup,
    IDMSFile,
    IDMSNode,
    IDMSNodeBanState,
    IDMSSettings,
    IDMSSoftwareBundle,
    IPortForwardingRule
} from "dms_commons";
import {TwoFactorRequest} from "../pages/App/App";
import DMSNodesView from "./DMSNodesView";

interface IProps extends WithSnackbarProps {
    targetNodeInfoModeConfig?: {
        targetNode: IDBNode,
        onCancel: () => void
    };
    apiUsers: IDBUser[];
    twoFactorHandler: (request: TwoFactorRequest) => void;
    cancelTwoFactorRequest: () => void;
    classes: any;
    globalSettings?: IDMSSettings;
    isLoadingNodes?: boolean;
    dmsFiles: IDMSFile[];
    isLoadingFiles: boolean;
    onLoadDataRequested: () => void;
    renderFilesTable: (fullTable: boolean, files?: IDMSFile[], isLoading?: boolean, onRowSelected?: (f: IDMSFile, index: number) => void) => void;
    dmsOnlineNodes: Map<string, IDMSNode>;
    bannedNodes: IDMSNodeBanState[];
    currentUser?: IDBUser;
    dmsClient: DMSWSClient;
    dmsRestClient: DMSRESTApiClient;
    logLine: (line: string) => void;
    definedSoftware: IDMSSoftwareBundle[];
    portRules: IPortForwardingRule[];
    onLoadPortRulesRequested: () => void;
    isLoadingPortRules: boolean;
    dmsUserGroups?: IDBUserGroup[];
}

interface IState {
    apiNodes?: Map<string, IDBNode>;
}

export default class DMSAPIClientsView extends React.Component<IProps, IState> {
    public state: IState = {};

    public componentDidMount() {
        this.initData();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.apiUsers === prevProps.apiUsers && this.props.dmsOnlineNodes === prevProps.dmsOnlineNodes) {
            return;
        }

        this.initData();
    }

    public render() {
        const {
            twoFactorHandler,
            cancelTwoFactorRequest,
            classes,
            globalSettings,
            dmsFiles,
            isLoadingFiles,
            onLoadDataRequested,
            isLoadingNodes,
            currentUser,
            dmsOnlineNodes,
            bannedNodes,
            dmsClient,
            dmsRestClient,
            logLine,
            definedSoftware,
            portRules,
            onLoadPortRulesRequested,
            isLoadingPortRules,
            enqueueSnackbar,
            closeSnackbar,
            dmsUserGroups
        } = this.props;

        const {apiNodes} = this.state;

        return <DMSNodesView
            tableKey={"api-clients-table"}
            mode={"api_clients"}
            twoFactorHandler={twoFactorHandler}
            cancelTwoFactorRequest={cancelTwoFactorRequest}
            classes={classes}
            globalSettings={globalSettings}
            dmsNodes={apiNodes ?? new Map<string, IDBNode>()}
            bannedNodes={bannedNodes}
            dmsOnlineNodes={dmsOnlineNodes}
            onLoadDataRequested={onLoadDataRequested}
            dmsFiles={dmsFiles}
            currentUser={currentUser}
            isLoadingFiles={isLoadingFiles}
            isLoadingNodes={isLoadingNodes}
            renderFilesTable={() => null}
            dmsClient={dmsClient}
            dmsRestClient={dmsRestClient}
            portRules={portRules}
            logLine={logLine}
            definedSoftware={definedSoftware}
            onLoadPortRulesRequested={onLoadPortRulesRequested}
            isLoadingPortRules={isLoadingPortRules}
            enqueueSnackbar={enqueueSnackbar}
            closeSnackbar={closeSnackbar}
            dmsUserGroups={dmsUserGroups}/>;
    }

    private initData = () => {
        // converts the api users to IDBNode entities,
        // allowing displaying api clients as nodes, without having them in the database
        const {apiUsers} = this.props;

        const apiNodes = new Map<string, IDBNode>();

        apiUsers.forEach(u => {
            const dbNode: IDBNode = {
                uid: u.username,
                clientName: u.apiType!,
                lastIp: u.lastIp!,
                location: (u.memberOf ?? []).join(","),
                operatingSystem: u.apiType ?? "N/A",
                lastConnection: u.lastLogin,
                createdBy: "dms_server",
                cpuArch: u.apiType ?? "N/A"
            };

            apiNodes[dbNode.uid] = dbNode;
            apiNodes.set(dbNode.uid, dbNode);
        });

        this.setState({
            apiNodes
        });
    };
}
