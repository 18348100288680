import {createTheme, Theme} from '@mui/material/styles';
import {createStyles} from '@mui/styles';
import {deepOrange, green, purple} from "@mui/material/colors";

export const drawerWidth = 204;

export const appTheme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        mode: "dark",
        background: {
            default: "rgb(48,48,48)",
            paper: "rgb(51,51,51)"
        },
        primary: {
            main: green["500"]
        },
        secondary: {
            main: green["500"],
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiInputLabel: {
            defaultProps: {
                variant: "standard"
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {}
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    '&[class*="variantSuccess"]': {
                        backgroundColor: 'rgb(23,147,23)',
                        textColor: "white",
                        color: "white",
                        fontWeight: 600
                    },
                    '&[class*="variantError"]': {
                        backgroundColor: 'rgb(210,49, 49)',
                        textColor: "white",
                        color: "white",
                        fontWeight: 600
                    },
                    '&[class*="variantInfo"]': {
                        backgroundColor: 'rgb(49,49, 49)',
                        textColor: "white",
                        color: "white",
                        fontWeight: 600
                    },
                    // .. etc for other variants
                }
            }
        },
    }
});

export const appThemeLight = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        mode: "light",
        primary: {
            main: "rgb(157,200, 243)"
        },
        secondary: {
            main: green["500"],
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiInputLabel: {
            defaultProps: {
                variant: "standard"
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    '&[class*="variantSuccess"]': {
                        backgroundColor: 'rgb(23,147,23)',
                        textColor: "white",
                        color: "white",
                        fontWeight: 600
                    },
                    '&[class*="variantError"]': {
                        backgroundColor: 'rgb(210,49, 49)',
                        textColor: "white",
                        color: "white",
                        fontWeight: 600
                    },
                    '&[class*="variantInfo"]': {
                        backgroundColor: 'rgb(49,49, 49)',
                        textColor: "white",
                        color: "white",
                        fontWeight: 600
                    },
                    // .. etc for other variants
                }
            }
        },
    }
});

// for reference: https://material-ui.com/guides/typescript/
export const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "normal"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
    },
    dialogTextField: {},
    chipBarContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: "rgb(51,51,51)",
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
        color: "white"
    },
    menuButton: {
        marginRight: 36,
        color: "white"
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    logItemNodeCell: {
        display: "flex",
        alignItems: "center"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    contentNoPadding: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    paper: {
        padding: theme.spacing(2),
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    purple: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
    },
    table: {},
    fab: {
        position: "fixed",
        right: theme.spacing(2),
        bottom: theme.spacing(2)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        backdropFilter: "grayscale(100%) blur(2px)",
        display: "flex",
        flexDirection: "column"
    },
    backdropMessage: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
    },
    logo: {
        maxWidth: 48,
        margin: 8
    },
    listItem: {
        textColor: "red"
    },
    clusterWorker: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
        height: 32,
        width: 32,
        borderRadius: 4,
        marginLeft: 8,
        marginRight: 8
    },
    clusterWorkerCurrent: {
        border: "2px solid white",
        fontWeight: "bold",
    },
    clusterWorkerOnline: {
        backgroundColor: "#479218"
    },
    clusterWorkerOffline: {
        backgroundColor: "rgb(238,40,40)"
    },
    userViewContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    gravatarStyle: {
        borderRadius: "50%",
        transition: "all 250ms ease-in-out",
    },
    userConnectionIndicator: {
        position: "absolute",
        top: "12px",
        left: "13px",
        height: "10px",
        width: "10px",
        borderRadius: "5px",
        border: "2px solid white",
        transition: "all 250ms ease-in-out"
    }
});
