import React from "react";
import {
    Button,
    Fab,
    Grid, LinearProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {VariantType, WithSnackbarProps} from "notistack";
import ModalDialog from "./ModalDialog";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import {
    IDMSFile,
    DMSWSClient,
    DMSRESTApiClient
} from "dms_commons";
import DMSFilesTable from "./DMSFilesTable";
import UploadFileState from "../enums/UploadFileState";

interface IProps extends WithSnackbarProps {
    classes: any;
    dmsFiles: IDMSFile[];
    isLoadingFiles: boolean;
    onLoadDataRequested: () => void;
    logLine: (line: string) => void;
    dmsClient: DMSWSClient;
    dmsRestClient: DMSRESTApiClient;
}

interface IState {
    uploadFileState?: UploadFileState;
    uploadFileTarget?: File;
    fileMenuAnchorElements: any[];
    uploadFileProgress: number;
}

export default class DMSFilesView extends React.Component<IProps, IState> {
    public state: IState = {
        uploadFileProgress: 0,
        fileMenuAnchorElements: [],
    };

    public componentDidMount() {
    }

    public render = () => {
        const {classes, dmsFiles, isLoadingFiles} = this.props;
        const {uploadFileState, uploadFileTarget, uploadFileProgress} = this.state;

        return <Grid item xs={12}>
            <DMSFilesTable dmsFiles={dmsFiles}
                           isLoading={isLoadingFiles}
                           fullTable={true}
                           dmsRestClient={this.props.dmsRestClient}
                           onLoadDataRequested={this.props.onLoadDataRequested}
                           onRowSelected={() => {

                           }}
                           classes={classes}
                           enqueueSnackbar={this.props.enqueueSnackbar}
                           closeSnackbar={this.props.closeSnackbar}/>
            <Grid>
                <Fab onClick={() => {
                    this.setState({uploadFileState: UploadFileState.REQUESTED});
                }} className={classes.fab} color="primary" aria-label="add">
                    <AddIcon/>
                </Fab>
            </Grid>
            <ModalDialog
                open={uploadFileState !== undefined && uploadFileState !== UploadFileState.INITIAL}
                title={"Upload a file"}
                message={"Only zip files are supported"}
                buttonOkTitle={"Upload"}
                buttonOkDisabled={uploadFileTarget === undefined || uploadFileState === UploadFileState.UPLOADING}
                buttonCancelDisabled={uploadFileState === UploadFileState.UPLOADING}
                onOk={() => {
                    this.onUploadFileRequested(uploadFileTarget!);
                }}
                onCancel={() => {
                    this.setState({
                        uploadFileState: UploadFileState.INITIAL,
                        uploadFileTarget: undefined,
                        uploadFileProgress: 0
                    });
                }
                }>
                <Button
                    variant="contained"
                    style={{
                        minWidth: 220,
                        width: "100%"
                    }}
                    disabled={uploadFileState === UploadFileState.UPLOADING}
                    component="label"
                >
                    <div>
                        {uploadFileTarget ? uploadFileTarget.name : "Browse"}
                        <input
                            accept={".zip"}
                            onChange={async (event) => {
                                if (event && event?.target?.files && event.target.files.length > 0) {
                                    const file = event.target.files[0];

                                    const fileExistsInDMS = dmsFiles.findIndex(value => {
                                        return value.filename === file.name;
                                    }) > -1;

                                    if (fileExistsInDMS) {
                                        this.displaySnackbar(`File ${file.name} already exists in DMS, please delete it or upload another file`, "info");
                                    } else {
                                        this.setState({uploadFileTarget: file});
                                    }
                                }
                            }}
                            type="file"
                            style={{display: "none"}}
                        />
                        <LinearProgress
                            variant={uploadFileProgress < 100 ? "determinate" : "indeterminate"}
                            value={uploadFileProgress}
                            style={{
                                transition: "all ease-in-out 250ms",
                                opacity: uploadFileState === UploadFileState.UPLOADING ? 1 : 0
                            }}/>
                    </div>
                </Button>
            </ModalDialog>
        </Grid>;
    };

    private onUploadFileRequested = async (file: File) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({
            uploadFileState: UploadFileState.UPLOADING,
            uploadFileProgress: 0
        });

        try {
            this.props.logLine("uploading file: " + file.name);

            const bucketUrl = await this.props.dmsRestClient.uploadFileToBucket(jwtToken, file, (progress => {
                this.setState({
                    uploadFileProgress: progress
                });
                console.log(progress);
            }));

            this.props.logLine(`successfully uploaded '${file.name}' to '${bucketUrl}'`);

            this.setState({uploadFileState: UploadFileState.SUCCEEDED, uploadFileProgress: 100});

            await this.props.onLoadDataRequested();

            this.setState({uploadFileState: UploadFileState.INITIAL, uploadFileTarget: undefined});
            this.displaySnackbar(`Successfully uploaded '${file.name}'`, "success");
        } catch (error) {
            this.setState({uploadFileState: UploadFileState.FAILED});
            console.error("could not upload file: " + error);
            this.displaySnackbar(`Failed to upload ${file.name} Error: ${error}`, "error");
        }
    };

    private displaySnackbar = (message: string, variant: VariantType = "info") => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {vertical: "top", horizontal: "center"}
        });
    };
}
