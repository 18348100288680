import React from "react";
import {
    Fade,
    Grid,
    Paper,
    TableContainer,
    Tooltip,
    Chip, IconButton
} from "@mui/material";
import {Column} from "@material-table/core";
import {VariantType, WithSnackbarProps} from "notistack";
import SignalCellularAltTwoToneIcon from "@mui/icons-material/SignalCellularAltTwoTone";
import SignalCellularConnectedNoInternet0BarTwoToneIcon
    from "@mui/icons-material/SignalCellularConnectedNoInternet0BarTwoTone";
import {
    DMSRESTApiClient,
    DMSWSClient, IBBCBridgeBagomatMessage, IBBCBridgeTagomatMessage,
    IDBNode,
    IDBUser, IDMSFile,
    IDMSNode,
    IDMSNodeBanState,
    IDMSSettings,
    IDMSSoftwareBundle,
    Util,

} from "dms_commons";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import DMSPersistentTable from "./DMSPersistentTable";
import DMSNodeHealthView from "./DMSNodeHealthView";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import Flag from 'react-world-flags';

interface IProps extends WithSnackbarProps {
    classes: any;
    authToken?: string;
    viewerMode?: boolean;
    globalSettings?: IDMSSettings;
    dmsNodes: Map<string, IDBNode>;
    dmsOnlineNodes: Map<string, IDMSNode>;
    isLoadingNodes?: boolean;
    dmsFiles: IDMSFile[];
    isLoadingFiles: boolean;
    onLoadDataRequested: () => void;
    renderFilesTable: (fullTable: boolean, files?: IDMSFile[], isLoading?: boolean, onRowSelected?: (f: IDMSFile, index: number) => void) => void;
    bannedNodes: IDMSNodeBanState[];
    currentUser?: IDBUser;
    dmsClient: DMSWSClient;
    dmsRestClient: DMSRESTApiClient;
    logLine: (line: string) => void;
    definedSoftware: IDMSSoftwareBundle[];
    lightTheme?: boolean;
}

interface IState {
    dmsOnlineNodesTableColumns: Column<IDBNode>[];
    nodes: IDBNode[];
}

export default class DMSHealthView extends React.Component<IProps, IState> {
    public state: IState = {
        dmsOnlineNodesTableColumns: [],
        nodes: []
    };

    public componentDidMount() {
        this.initDmsNodeColumns();
    }

    public componentWillUnmount() {
        this.setState({
            dmsOnlineNodesTableColumns: []
        });
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.dmsNodes === prevProps.dmsNodes && this.props.dmsOnlineNodes === prevProps.dmsOnlineNodes) {
            return;
        }

        this.initDmsNodeColumns();
    }

    private readonly tableKey = "health-table";

    private initDmsNodeColumns = () => {
        //parent's dmsNodes changed
        const {dmsNodes, dmsOnlineNodes, bannedNodes, viewerMode} = this.props;

        const uniqueNodeLocations = new Array<string>();
        const uniqueClientNames = new Array<string>();

        let nodes = new Array<IDBNode>();

        for (const key in dmsNodes) {
            const n = dmsNodes[key];

            nodes.push(n);

            if (uniqueNodeLocations.indexOf(n.location) === -1) {
                uniqueNodeLocations.push(n.location);
            }

            let nodeVersionString: string | undefined;

            if (n.clientVersion) {
                nodeVersionString = n.clientVersion.major + "." + n.clientVersion.minor + "." + n.clientVersion.build;
            }

            const clientName = `${n.clientName} ${nodeVersionString ? `(${nodeVersionString})` : "No Client"}`;

            if (uniqueClientNames.indexOf(clientName) === -1) {
                uniqueClientNames.push(clientName);
            }
        }

        const locationFilter = {};
        uniqueNodeLocations.forEach(n => {
            locationFilter[n] = n;
        });

        const clientFilter = {};
        uniqueClientNames.forEach(n => {
            clientFilter[n] = n;
        });

        const softwareFilter = {};

        this.props.definedSoftware.forEach(s => {
            softwareFilter[s.name] = s.name;
        });

        const dmsOnlineNodesTableColumns: Column<IDBNode>[] = [
            {
                title: "Online",
                type: "boolean",
                width: "25px",
                align: "center",
                customSort: (a, b) => {
                    const isNodeAOnline = dmsOnlineNodes[a.uid] !== undefined;
                    const isNodeBOnline = dmsOnlineNodes && dmsOnlineNodes[b.uid] !== undefined;

                    return (isNodeAOnline === isNodeBOnline) ? 0 : isNodeAOnline ? -1 : 1;
                },
                render: (rowData => {
                    const isNodeOnline = dmsOnlineNodes && dmsOnlineNodes[rowData.uid] !== undefined;
                    const isNodeBanned = bannedNodes && bannedNodes.findIndex(w => w.uid === rowData.uid) > -1;

                    return isNodeBanned ? <IconButton onClick={() => {
                        this.displaySnackbar(`Node '${rowData.uid}' is banned, you can unban it in the settings.`, "warning");
                    }
                    }> <VpnLockIcon/></IconButton> : <div><Fade
                        style={{display: isNodeOnline ? "block" : "none"}}
                        in={isNodeOnline}>
                        <SignalCellularAltTwoToneIcon aria-label={"online"} htmlColor="rgb(0,225,0)"/></Fade>
                        <Fade
                            style={{display: isNodeOnline ? "none" : "block"}}
                            in={!isNodeOnline}>
                            <SignalCellularConnectedNoInternet0BarTwoToneIcon aria-label={"offline"}
                                                                              htmlColor={"orange"}/></Fade>
                    </div>;
                })
            },
            {
                title: "Id",
                field: "uid",
                // align: "center",
                filtering: false,
            },
            {
                title: "State",
                align: "center",
                render: rowData => {
                    const nodeCurrentInstance = dmsNodes[rowData.uid] as IDBNode;

                    if (!nodeCurrentInstance) {
                        return;
                    }

                    if (nodeCurrentInstance.lastBridgeMessage) {
                        return <div style={{
                            display: "flex",
                            justifyContent: "center",
                            "alignContent": "center"
                        }}><Tooltip
                            title={JSON.stringify(nodeCurrentInstance.lastBridgeMessage, undefined, 4)}>
                            <Chip
                                avatar={<Flag
                                    // @ts-ignore
                                    code={DMSHealthView.getLocaleFlag(nodeCurrentInstance.lastBridgeMessage.locale)}
                                    size={32}
                                    // @ts-ignore
                                />} size={"small"} label={nodeCurrentInstance.lastBridgeMessage.state}/>
                        </Tooltip>
                        </div>;
                    } else {
                        return <Chip size={"small"} label={"N/A"}/>;
                    }
                }
            },
            {
                title: "Data",
                align: "center",
                render: rowData => {
                    const nodeCurrentInstance = dmsNodes[rowData.uid] as IDBNode;

                    if (!nodeCurrentInstance) {
                        return;
                    }

                    if (nodeCurrentInstance.lastBridgeMessage) {
                        return DMSHealthView.renderBridgeMessageData(nodeCurrentInstance.lastBridgeMessage, true);
                    }
                }
            },
            {
                title: "Updated",
                defaultSort: "desc",
                customSort: (a, b) => {
                    const aCurrentInstance = dmsNodes[a.uid] as IDBNode;
                    const bCurrentInstance = dmsNodes[b.uid] as IDBNode;

                    const aTs = (aCurrentInstance?.lastBridgeMessage?.timestamp as any)?._seconds ?? -1;
                    const bTs = (bCurrentInstance?.lastBridgeMessage?.timestamp as any)?._seconds ?? -1;

                    return aTs - bTs;
                },
                align: "center",
                render: rowData => {
                    const nodeCurrentInstance = dmsNodes[rowData.uid] as IDBNode;

                    if (!nodeCurrentInstance) {
                        return;
                    }

                    if (nodeCurrentInstance.lastBridgeMessage) {
                        return <p>{Util.relativeDateTimeStringFromDBTimestamp(nodeCurrentInstance.lastBridgeMessage.timestamp)}</p>;
                    }
                }
            },
            {
                title: "Location",
                field: "location",
                //align: "center",
                filtering: true,
                lookup: locationFilter,
                defaultFilter: viewerMode ? undefined : DMSPersistentTable.getDefaultFilter(this.tableKey, "location")
            },
            {
                title: "Software",
                align: "center",
                lookup: softwareFilter,
                filtering: true,
                field: "software",
                defaultFilter: viewerMode ? undefined : DMSPersistentTable.getDefaultFilter(this.tableKey, "software"),
                customFilterAndSearch: (filter, rowData) => {
                    if (filter.length > 0) {
                        return (rowData.installedSoftware ?? []).findIndex(s => s.name === filter[0]) > -1;
                    }
                    return true;
                },
                customSort: (a, b) => {
                    return Util.compareVersions(a.clientVersion, b.clientVersion);
                },
                render: rowData => {
                    return (rowData.installedSoftware ?? []).filter(s => {
                        return this.props.definedSoftware.findIndex(x => {
                            return x.name === s.name;
                        }) > -1;
                    }).map((s, i) => {
                        let nodeVersionString = s.version.major + "." + s.version.minor + "." + s.version.build;
                        return <Chip key={i} style={{margin: 2}} size={"small"}
                                     label={`${s.name} (${nodeVersionString})`}/>;
                    });
                }
            },
        ];

        dmsOnlineNodesTableColumns.forEach(c => {
            c.width = 1;
        });

        this.setState({
            dmsOnlineNodesTableColumns,
            nodes
        });
    };

    public render = () => {
        const {classes, viewerMode} = this.props;
        const {isLoadingNodes, dmsNodes} = this.props;
        const {nodes, dmsOnlineNodesTableColumns} = this.state;

        return <Grid item xs>
            <TableContainer component={Paper}>
                <DMSPersistentTable
                    key={this.tableKey}
                    isLoading={isLoadingNodes}
                    title={"Health"}
                    tableKey={this.tableKey}
                    columns={dmsOnlineNodesTableColumns}
                    data={nodes}
                    detailPanel={info => {
                        const nodeCurrentInstance = dmsNodes[info.rowData.uid] as IDBNode;

                        if (!nodeCurrentInstance) {
                            return;
                        }

                        return <DMSNodeHealthView
                            dmsNodeBridgeMessagesQuery={async query => {
                                const jwtToken = this.getJwtToken();

                                if (jwtToken === null) {
                                    console.log("missing jwt token");
                                    return {
                                        page: 0,
                                        data: [],
                                        totalCount: 0
                                    };
                                }

                                const bridgeMessages = await this.props.dmsRestClient.getNodeBridgeMessages(jwtToken, nodeCurrentInstance.uid, query.page * query.pageSize, query.pageSize);

                                return {
                                    page: query.page,
                                    data: bridgeMessages,
                                    totalCount: 10000
                                };
                            }}
                            classes={classes}
                            logLine={this.props.logLine} displaySnackbar={this.displaySnackbar}
                            enqueueSnackbar={this.props.enqueueSnackbar}
                            closeSnackbar={this.props.closeSnackbar}/>;
                    }}
                    options={{
                        headerStyle: {
                            position: 'sticky', top: 0,
                            backgroundColor: this.props.lightTheme ? undefined : "rgb(65,65,65)",
                        },
                        maxBodyHeight: '650px',
                        selection: false,
                        showTitle: !viewerMode,
                        draggable: true,
                        grouping: !viewerMode,
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100],
                        loadingType: "overlay",
                        filtering: true,
                        emptyRowsWhenPaging: false,
                        detailPanelType: "single",
                        padding: "dense",
                        idSynonym: "uid"
                    }}
                />
                <Grid>
                </Grid>
            </TableContainer>
        </Grid>;
    };

    public static getLocaleFlag = (locale: string) => {
        if (!locale) {
            return "GB";
        }
        let flag = locale;

        switch (locale.toLowerCase()) {
            case "el":
                flag = "GR";
                break;
            case "en":
                flag = "GB";
                break;
        }

        return flag;
    };

    public static renderBridgeMessageData = (data: any | IBBCBridgeTagomatMessage | IBBCBridgeBagomatMessage, vertical: boolean) => {
        return (<div style={{
            display: "flex",
            alignItems: "center", flexDirection: vertical ? "column" : "row",
            justifyContent: "center",
            alignContent: "center"
        }}>
            {data.airline ?
                <img alt={data.airline} style={{height: 28, margin: 4}}
                     src={"/airlines/" + data.airline + ".png"}/> : undefined}
            {data.pax && data.flight ?
                <Chip size={"small"}
                      label={data.pax + ", " + data.flight}/> : undefined}
            {data.model && data.model.flight ?
                <img alt={data.airline} style={{height: 28, margin: 4}}
                     src={"/airlines/" + data.model.flight.airline + ".png"}/> : undefined}
            {data && data.text1 ?
                <Chip size={"small"} label={data.text1}/> : undefined}
        </div>);
    };

    private displaySnackbar = (message: string, variant: VariantType = "info") => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {vertical: "top", horizontal: "center"}
        });
    };

    private getJwtToken = () => {
        const {viewerMode, authToken} = this.props;

        if (viewerMode) {
            return authToken!;
        }

        return LocalStorageHelper.getAuthToken();
    };
}
