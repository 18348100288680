import * as speakeasy from 'speakeasy';

export enum IEnable2FARequestState {
    Initial,
    ShowingQR,
    VerifyingToken,
    SavingToken,
    Done
}

export default interface IEnable2FARequest {
    state?: IEnable2FARequestState
    username?: string;
    password?: string;
    secret?: speakeasy.GeneratedSecret;
    lastVerificationToken?: string;
}