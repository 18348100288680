import {withStyles} from '@mui/styles';
import React from "react";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import {DMSRESTApiClient} from "dms_commons";
import {VariantType, withSnackbar, WithSnackbarProps} from "notistack";
import EndpointHelper from "../../helpers/EndpointHelper";
import Constants from "../../Constants";
import {appTheme, styles} from "../App/Styles";
import ModalDialog from "../../components/ModalDialog";
import * as speakeasy from "speakeasy";
import IEnable2FARequest, {IEnable2FARequestState} from "../../models/IEnable2FARequest";
import LockIcon from '@mui/icons-material/Lock';
import DMSUserView from "../../components/DMSUserView";
import * as  QRCode from 'qrcode.react';
import ReactCodeInput from "react-verification-code-input";
import {ThemeProvider} from '@mui/material/styles';
import {Box, Button, Container, CssBaseline, Fade, FormHelperText, Link, TextField, Typography} from "@mui/material";
import {Navigate} from "react-router-dom";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://www.bbcairport.com">
                BB Computerteknikk AS
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

interface IProps extends WithSnackbarProps {
    history: any;
    classes: any;
}

interface IState {
    username: string;
    password: string;
    isLoading: boolean;
    enable2FARequest?: IEnable2FARequest;
    verify2FARequest?: {
        lastVerificationToken?: string,
        username?: string,
        password?: string,
        isLoading?: boolean
    };
}

class Login extends React.Component<IProps, IState> {
    public state: IState = {
        username: "",
        password: "",
        isLoading: false
    };
    //region Private Fields

    private dmsRestClient!: DMSRESTApiClient;

    //endregion

    componentDidMount() {
        const env = Constants.env;

        const dmsScheme = EndpointHelper.getDmsScheme(env);
        const dmsHost = EndpointHelper.getDmsHostname(env);
        const dmsPortNumber = EndpointHelper.getDmsPortNumber(env);

        this.dmsRestClient = new DMSRESTApiClient(dmsScheme, dmsHost, dmsPortNumber);
    }

    public render() {
        const {classes} = this.props;

        const authToken = LocalStorageHelper.getAuthToken();

        const versionString = Constants.clientVersion.major + "." + Constants.clientVersion.minor + "." + Constants.clientVersion.build;

        return (
            authToken
                ? <Navigate to="/"/>
                : <ThemeProvider theme={appTheme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline/>
                        {this.renderSetupTwoFactorAuthDialog()}
                        {this.renderVerifyTwoFactorAuthDialog()}
                        <Fade timeout={1000} in={true}>
                            <div className={classes.paper}>
                                <div style={{display: "flex", alignItems: "center", flexDirection: "column", padding: 8}}>
                                    <img alt={"logo"} style={{height: 150}} src={"bbclogo.png"}/>
                                    <Typography style={{
                                        marginLeft: "-40px",
                                        marginTop: 16
                                    }} component="h1" variant="h5">DMS Admin {versionString}</Typography>
                                </div>
                                <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        value={this.state.username}
                                        onChange={(event) => {
                                            let newValue = "";
                                            if (event && event.target && event.target.value) {
                                                newValue = event.target.value;
                                            }

                                            this.setState({
                                                username: newValue
                                            });
                                        }}
                                        id="username"
                                        label="Username"
                                        name="username"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        value={this.state.password}
                                        onChange={(event) => {
                                            let newValue = "";
                                            if (event && event.target && event.target.value) {
                                                newValue = event.target.value;
                                            }

                                            this.setState({
                                                password: newValue
                                            });
                                        }}
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                    <Button
                                        disabled={this.state.isLoading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Sign In
                                    </Button>
                                </form>
                                <Box mt={8}>
                                    <Copyright/>
                                </Box>
                            </div>
                        </Fade>
                    </Container>
                </ThemeProvider>
        );
    }

    private handleSubmit = async (event) => {
        event.preventDefault();

        if (!event.target.checkValidity()) {
            return;
        }

        const {username, password} = this.state;

        if (!username || !password) {
            return;
        }

        try {
            this.setState({
                isLoading: true
            });

            const jwt = await this.dmsRestClient.login(username, password);

            LocalStorageHelper.setAuthToken(jwt);

            this.props.history.push("/");
        } catch (error: any) {
            if (error.response && error.response.status && error.response.status === 206) {
                this.setState({
                    enable2FARequest: {
                        state: IEnable2FARequestState.Initial,
                        username,
                        password,
                        secret: speakeasy.generateSecret({
                            name: username + " @ BBC DMS",
                        })
                    }
                });
                return;
            } else if (error.response && error.response.status && error.response.status === 203) {
                //needs auth token
                this.setState({
                    verify2FARequest: {
                        username,
                        password,
                    }
                });
                return;
            } else if (error.response && error.response.status && error.response.status === 400) {
                this.displaySnackbar("Failed to login, please make sure you have entered a valid username and password", "error");
                return;
            }

            console.error(error);
            this.displaySnackbar("Failed to sign in " + error, "error");
        } finally {
            this.setState({
                isLoading: false
            });
        }

        return false;
    };

    private displaySnackbar = (message: string, variant: VariantType = "info") => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {vertical: "top", horizontal: "center"}
        });
    };

    private renderSetupTwoFactorAuthDialog = () => {
        const {classes} = this.props;

        const enable2FARequest = this.state.enable2FARequest;

        if (!enable2FARequest) {
            return undefined;
        }

        let buttonOkDisabled = false;
        let buttonCancelDisabled = false;
        let buttonOkTitle = "Ok";
        let buttonCancelTitle = "Cancel";
        let buttonCancelHidden = false;
        let buttonOkLoading = false;

        switch (enable2FARequest?.state) {
            case IEnable2FARequestState.Initial:
                buttonOkTitle = "Next";
                break;
            case IEnable2FARequestState.ShowingQR:
                buttonCancelTitle = "Back";
                buttonOkTitle = "Next";
                break;
            case IEnable2FARequestState.VerifyingToken:
                buttonOkDisabled = false;
                buttonCancelTitle = "Back";
                buttonOkTitle = "Next";
                buttonOkDisabled = true;
                break;
            case IEnable2FARequestState.SavingToken:
                buttonOkDisabled = true;
                buttonCancelHidden = true;
                buttonOkTitle = "Next";
                break;
            case IEnable2FARequestState.Done:
                buttonOkTitle = "Complete";
                buttonCancelHidden = true;
                break;
        }

        return (
            <ModalDialog open={this.state.enable2FARequest !== undefined} title={"Setup Two-factor authentication"}
                         buttonOkDisabled={buttonOkDisabled}
                         buttonCancelDisabled={buttonCancelDisabled}
                         buttonOkIsLoading={buttonOkLoading}
                         buttonOkTitle={buttonOkTitle}
                         buttonCancelTitle={buttonCancelTitle}
                         hideCancelButton={buttonCancelHidden}
                         onOk={() => {
                             switch (enable2FARequest?.state) {
                                 case IEnable2FARequestState.Initial:
                                     this.setState(prevState => ({
                                         enable2FARequest: {
                                             ...prevState.enable2FARequest,
                                             state: IEnable2FARequestState.ShowingQR
                                         }
                                     }));
                                     break;
                                 case IEnable2FARequestState.ShowingQR:
                                     this.setState(prevState => ({
                                         enable2FARequest: {
                                             ...prevState.enable2FARequest,
                                             state: IEnable2FARequestState.VerifyingToken
                                         }
                                     }));
                                     break;
                                 case IEnable2FARequestState.VerifyingToken:
                                     break;
                                 case IEnable2FARequestState.SavingToken:
                                     break;
                                 case IEnable2FARequestState.Done:
                                     this.setState({
                                         enable2FARequest: undefined
                                     });
                                     break;
                             }
                         }}
                         onCancel={() => {
                             switch (enable2FARequest?.state) {
                                 case IEnable2FARequestState.ShowingQR:
                                     this.setState(prevState => ({
                                         enable2FARequest: {
                                             ...prevState.enable2FARequest,
                                             state: IEnable2FARequestState.Initial
                                         }
                                     }));
                                     break;
                                 case IEnable2FARequestState.VerifyingToken:
                                     this.setState(prevState => ({
                                         enable2FARequest: {
                                             ...prevState.enable2FARequest,
                                             state: IEnable2FARequestState.ShowingQR
                                         }
                                     }));
                                     break;
                                 case IEnable2FARequestState.SavingToken:
                                     break;
                                 case IEnable2FARequestState.Done:
                                     this.setState({
                                         enable2FARequest: undefined
                                     });
                                     break;
                                 default:
                                     this.setState({enable2FARequest: undefined});
                                     break;
                             }
                         }}>
                <Container>
                    {enable2FARequest?.state === IEnable2FARequestState.Initial ? <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <DMSUserView classes={classes} username={enable2FARequest.username ?? ""}/>
                        <FormHelperText
                            style={{
                                textAlign: "center",
                                margin: 8
                            }}>{`Two-factor authentication is required for user ${enable2FARequest?.username}, please follow the steps in this guide to setup and enable two-factor authentication.`}</FormHelperText>
                    </div> : undefined}
                    {enable2FARequest?.state === IEnable2FARequestState.ShowingQR ? <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <QRCode style={{margin: 8}} value={enable2FARequest?.secret?.otpauth_url}/>
                        <FormHelperText
                            style={{
                                transition: "opacity ease-in-out 250ms",
                                textAlign: "center"
                            }}>Scan this QR code with your authenticator app(e.g. Google Authenticator or
                            Microsoft Authenticator) and
                            click
                            next when
                            you're ready.</FormHelperText>
                    </div> : undefined}
                    {enable2FARequest?.state === IEnable2FARequestState.VerifyingToken || enable2FARequest?.state === IEnable2FARequestState.SavingToken ?
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <ReactCodeInput
                                loading={enable2FARequest?.state === IEnable2FARequestState.SavingToken}
                                disabled={enable2FARequest?.state === IEnable2FARequestState.SavingToken}
                                onComplete={async (token) => {
                                    if (token === enable2FARequest?.lastVerificationToken) {
                                        return;
                                    }

                                    let verified: boolean = false;

                                    try {
                                        enable2FARequest!.lastVerificationToken = token;
                                        verified = speakeasy.totp.verify({
                                            secret: enable2FARequest!.secret!.ascii,
                                            encoding: "ascii",
                                            token: token,
                                            window: 1000
                                        });

                                        if (!verified) {
                                            this.displaySnackbar("Failed to verify the token, please make sure it's correct", "warning");
                                        } else {
                                            this.setState(prevState => ({
                                                enable2FARequest: {
                                                    ...prevState.enable2FARequest,
                                                    state: IEnable2FARequestState.SavingToken
                                                }
                                            }));

                                            const jwtToken = await this.dmsRestClient.login(enable2FARequest!.username!, enable2FARequest!.password!, enable2FARequest!.secret!.ascii!);
                                            LocalStorageHelper.setAuthToken(jwtToken);

                                            this.setState(prevState => ({
                                                enable2FARequest: {
                                                    ...prevState.enable2FARequest,
                                                    state: IEnable2FARequestState.Done
                                                }
                                            }));
                                        }
                                    } catch (error: any) {
                                        if (error && error.response && error.response.status === 405) {
                                            this.displaySnackbar("You are not allowed to enable two-factor authentication", "error");
                                        } else {
                                            this.displaySnackbar("Failed to enable two-factor authentication, Error:" + JSON.stringify(error), "error");
                                        }
                                    }
                                }}/>
                            <FormHelperText
                                style={{
                                    textAlign: "center",
                                    margin: 8
                                }}>Verify the pairing by entering the token from your authenticator
                                app.</FormHelperText>
                        </div> : undefined}
                    {enable2FARequest?.state === IEnable2FARequestState.Done ? <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <LockIcon style={{height: 64, width: 64}}/>
                        <FormHelperText
                            style={{
                                textAlign: "center",
                                margin: 8
                            }}>Successfully enabled two-factor authentication, please make sure you keep your
                            two-factor
                            app backed up, you won't be able to login into DMS without it.</FormHelperText>
                    </div> : undefined}
                </Container>
            </ModalDialog>);
    };

    private renderVerifyTwoFactorAuthDialog = () => {
        const verify2FARequest = this.state.verify2FARequest;

        if (!verify2FARequest) {
            return;
        }

        return (
            <ModalDialog open={this.state.verify2FARequest !== undefined}
                         title={"🔑 Two-factor authentication"}
                         buttonOkTitle={"Send"}
                         hideOkButton={true}
                         onCancel={() => {
                             this.setState({
                                 verify2FARequest: undefined,
                                 password: ""
                             });
                         }}>
                <Container>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <ReactCodeInput
                            loading={verify2FARequest?.isLoading}
                            disabled={verify2FARequest?.isLoading}
                            onComplete={async (token) => {
                                if (token === verify2FARequest?.lastVerificationToken) {
                                    return;
                                }

                                try {
                                    verify2FARequest!.lastVerificationToken = token;

                                    this.setState(prevState => ({
                                        verify2FARequest: {
                                            ...prevState.verify2FARequest,
                                            isLoading: true
                                        }
                                    }));

                                    const jwtToken = await this.dmsRestClient.login(verify2FARequest!.username!, verify2FARequest!.password!, undefined, token);
                                    LocalStorageHelper.setAuthToken(jwtToken);

                                    this.setState(prevState => ({
                                        verify2FARequest: {
                                            ...prevState.verify2FARequest,
                                            isLoading: false
                                        }
                                    }));
                                } catch (error: any) {
                                    this.setState(prevState => ({
                                        verify2FARequest: {
                                            ...prevState.verify2FARequest,
                                            isLoading: false
                                        }
                                    }));

                                    if (error.response && error.response.status && error.response.status === 403) {
                                        this.displaySnackbar("Failed to login, please make sure the provided credentials are valid", "error");
                                        return;
                                    }

                                    this.displaySnackbar("Failed to login with two-factor authentication, Error:" + JSON.stringify(error), "error");
                                }
                            }}/>
                        <FormHelperText
                            style={{
                                textAlign: "center",
                                margin: 8
                            }}>Please enter the auth token from your authenticator app</FormHelperText>
                    </div>
                </Container>
            </ModalDialog>);
    };
}

export default withStyles(styles, {withTheme: true})(withSnackbar(Login));
