import React from "react";
import App from "../App/App";
import {VIEW_TYPE} from "../../enums/VIEW_TYPE";
import {appThemeLight} from "../App/Styles";
import {LinearProgress, Typography, CssBaseline} from "@mui/material";
import {DMSRESTApiClient} from "dms_commons";
import EndpointHelper from "../../helpers/EndpointHelper";
import Constants from "../../Constants";
import {ThemeProvider} from '@mui/material/styles';
import withRouter from "../../helpers/withRouter";
import RouteComponentProps from "../../helpers/RouteComponentProps";

interface IProps extends RouteComponentProps<{ username: string, viewerToken: string }> {
    classes: any;
}

interface IState {
    username: string;
    viewerToken: string;
    isLoading: boolean;

    error?: Error;
    userToken?: string;
}

class Frame extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const {username, viewerToken} = this.props.router.params;

        this.state = {
            username,
            viewerToken,
            isLoading: true
        };
    }

    private dmsRestClient!: DMSRESTApiClient;

    public async componentDidMount() {
        const {username, viewerToken} = this.state;

        let userToken: string | undefined;

        try {
            const env = Constants.env;
            const dmsScheme = EndpointHelper.getDmsScheme(env);
            const dmsHost = EndpointHelper.getDmsHostname(env);
            const dmsPortNumber = EndpointHelper.getDmsPortNumber(env);

            this.dmsRestClient = new DMSRESTApiClient(dmsScheme, dmsHost, dmsPortNumber);

            userToken = await this.dmsRestClient.loginWithViewerToken(username, viewerToken);
        } catch (error) {
            this.setState({
                error: error as Error
            });
        } finally {
            this.setState({
                isLoading: false,
                userToken
            });
        }
    }

    public render() {
        const {isLoading, userToken, error} = this.state;

        if (isLoading || !userToken) {
            return <ThemeProvider theme={appThemeLight}>
                <div style={{
                    height: "100%",
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <title>DMS</title>
                    <CssBaseline/>
                    <div style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <LinearProgress color={"primary"} variant={isLoading ? "indeterminate" : "determinate"}
                                        style={{margin: 8, width: "50%"}}/>
                        <Typography
                            variant={"subtitle1"}>{error ? (error.toString() + " - please try again later") : "Connecting to DMS"}</Typography>
                    </div>
                </div>
            </ThemeProvider>;
        }

        return (<ThemeProvider theme={appThemeLight}>
            {
                // @ts-ignore
                <App
                    selectedView={VIEW_TYPE.VIEW_HEALTH}
                    viewerMode={true}
                    authToken={userToken}
                />
            }
        </ThemeProvider>);
    }
}

export default withRouter(Frame);
