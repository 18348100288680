import React from "react";
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import "./index.css";
import App from "./pages/App/App";
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound";
import * as serviceWorker from "./serviceWorker";
import {appTheme} from "./pages/App/Styles";
import {SnackbarProvider} from "notistack";
import Constants from "./Constants";
import Frame from "./pages/Frame/Frame";
import {ThemeProvider} from '@mui/material/styles';
import {StyledEngineProvider} from "@mui/material";
import Dash from "./pages/Dash/Dash";
// @ts-ignore

// https://codeburst.io/getting-started-with-react-router-5c978f70df91 (TODO: check url params)
// tslint:disable-next-line: no-empty-interface
interface IProps {

}

// tslint:disable-next-line: no-empty-interface
interface IState {

}

class AppRoutes extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        window.addEventListener("storage", () => {
            this.forceUpdate();
        });
    }

    // @ts-ignore
    private AppElement = <App/>;
    // @ts-ignore
    private LoginElement = <Login/>;

    private DashElement = <Dash/>;

    public render() {

        let versionString = "";

        versionString = `${Constants.clientVersion.major}.${Constants.clientVersion.minor}.${Constants.clientVersion.build}`;

        return <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <title>{`${Constants.clientTitle} - ${versionString}`}</title>
                <SnackbarProvider maxSnack={3}>
                    <Router>
                        <Routes>
                            <Route path="/" element={this.AppElement}/>
                            <Route path="/frame/:username?/:viewerToken?" element={<Frame/>}/>
                            <Route path="/login" element={this.LoginElement}/>
                            <Route path="/dash" element={this.DashElement}/>
                            <Route element={<NotFound/>}/>
                        </Routes>
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>;
    }
}

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppRoutes/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
