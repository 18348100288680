import MaterialTable, {MaterialTableProps} from "@material-table/core";
import React, {Component} from "react";
import {Typography} from "@mui/material";

interface IProps<RowData extends object> extends MaterialTableProps<RowData> {
    tableKey: string;
    emptyMessage?: string;
}

interface IState {
    searchVal: string | null;
}

export default class DMSPersistentTable<RowData extends object> extends Component<IProps<RowData>, IState> {
    public static getLastSearchTerm = (tableKey: string) => {
        return localStorage.getItem(DMSPersistentTable.getSearchKey(tableKey));
    };

    public static getSearchKey = (tableKey: string) => {
        return "search." + tableKey;
    };

    public static getDefaultFilter = (tableKey: string, itemKey: string) => {
        const data = localStorage.getItem(`filtering.${tableKey}.${itemKey}`);

        if (data) {
            try {
                const res = JSON.parse(data);

                if (res?.length === 0) {
                    return undefined;
                } else {
                    return res;
                }
            } catch (e) {

            }
        }

        return undefined;
    };

    public render() {
        return <MaterialTable
            onSearchChange={(searchText) => {
                localStorage.setItem(DMSPersistentTable.getSearchKey(this.props.tableKey), searchText);
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: this.props.emptyMessage ?
                        <Typography>{this.props.emptyMessage}</Typography> : undefined
                }
            }}
            onFilterChange={filters => {
                filters.forEach(filter => {
                    if (filter.column.filtering && filter.column.field) {
                        const serializedData = JSON.stringify(filter.value);
                        localStorage.setItem("filtering." + this.props.tableKey + "." + filter.column.field.toString(), serializedData);
                    }
                });
            }} {...this.props}/>;
    }
}