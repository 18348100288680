import React from "react";
import App from "../App/App";
import {VIEW_TYPE} from "../../enums/VIEW_TYPE";
import {appTheme} from "../App/Styles";
import {ThemeProvider} from '@mui/material/styles';
import RouteComponentProps from "../../helpers/RouteComponentProps";
import withRouter from "../../helpers/withRouter";
import {Navigate} from "react-router-dom";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";

interface IProps extends RouteComponentProps<{ username: string, viewerToken: string }> {
    classes: any;
}

interface IState {
}

class Dash extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const authToken = this.getJwtToken();
        this.hasAuthToken = Boolean(authToken);
        this.state = {};
    }

    private hasAuthToken: boolean = false;

    private getJwtToken = () => {
        return LocalStorageHelper.getAuthToken();
    };

    public async componentDidMount() {
    }

    public render() {
        return this.hasAuthToken ? (<ThemeProvider theme={appTheme}>
            {
                // @ts-ignore
                <App selectedView={VIEW_TYPE.VIEW_DASH}/>
            }
        </ThemeProvider>) : <Navigate to="/login"/>;
    }
}

export default withRouter(Dash);
