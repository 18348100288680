import React from "react";
import {Chip} from "@mui/material";
import {QuestionMark} from "@mui/icons-material";

interface IProps {
    classes: any;
    ipAddress?: string;
}

interface IState {

}

export default class DMSIPView extends React.Component<IProps, IState> {
    public render() {
        return <Chip
            deleteIcon={(this.props.ipAddress?.length ?? 0) > 0 ? <QuestionMark/> : undefined}
            onDelete={this.props.ipAddress ? event => {
                window.open("https://www.whois.com/whois/" + this.props.ipAddress, "_blank");
            } : undefined}
            size={"small"}
            variant={"outlined"}
            label={this.props.ipAddress ?? "N/A"}
        />;
    }
}