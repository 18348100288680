import React, {Component, ReactElement} from "react";
import clsx from "clsx";
import Constants from "../Constants";
import {VIEW_TYPE} from "../enums/VIEW_TYPE";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfoIcon from "@mui/icons-material/Info";
import DevicesIcon from "@mui/icons-material/Devices";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import {VariantType, WithSnackbarProps} from "notistack";
import MediaQuery from 'react-responsive';
import Gravatar from "react-gravatar";
import {DMSRESTApiClient, IDBNode, IDBUser, IDMSNode, IVersion, Util, ValidationHelper} from "dms_commons";
import ModalDialog from "./ModalDialog";
import DMSUserActivityView from "./DMSUserActivityView";
import IChangePasswordRequest from "../models/IChangePasswordRequest";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ReactMarkdown from "react-markdown";
import {
    AppBar,
    Badge,
    Chip,
    Container,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {TwoFactorRequest} from "../pages/App/App";

interface IProps extends WithSnackbarProps {
    twoFactorHandler: (request: TwoFactorRequest) => void;
    cancelTwoFactorRequest: () => void;
    classes: any;
    isDrawerOpen: boolean;
    handleDrawerOpen: () => void;
    selectedView: VIEW_TYPE;
    onToggleConsoleRequested: () => void;
    currentUser?: IDBUser;
    dmsNodes: Map<string, IDBNode>;
    dmsOnlineNodes: Map<string, IDMSNode>;
    onLogoutRequested: () => void;
    logLine: (line: string) => void;
    dmsRestClient: DMSRESTApiClient;
}

interface IState {
    newLogEvents: number;
    accountMenuAnchorElement: any;
    isAccountMenuOpen: boolean;
    mobileMenuAnchorElement: any;
    showNotificationView?: boolean;
    changePasswordRequest?: IChangePasswordRequest;
    changelogContext?: IChangelogContext;
}

interface IChangelogContext {
    lastVersion?: IVersion;
    currentVersion?: IVersion;
    changelogContents: string;
}

export default class AppHeader extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            accountMenuAnchorElement: null,
            isAccountMenuOpen: false,
            mobileMenuAnchorElement: null,
            newLogEvents: 0
        };
    }

    private clientVersionString = Constants.clientVersion.major + "." + Constants.clientVersion.minor + "." + Constants.clientVersion.build;

    public componentDidMount() {
        this.showChangelog();
    }

    public render() {
        const {
            classes,
            isDrawerOpen,
            handleDrawerOpen,
            selectedView,
            onToggleConsoleRequested,
            currentUser,
            dmsNodes,
            dmsOnlineNodes,
            onLogoutRequested,
            logLine
        } = this.props;

        const {
            newLogEvents,
            showNotificationView
        } = this.state;

        return <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: isDrawerOpen,
            })}
        >
            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    onClick={() => {
                        handleDrawerOpen();
                    }}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: isDrawerOpen,
                    })}
                >
                    <MenuIcon/>
                </IconButton>
                <img alt={"BBC Logo"} className={classes.logo} src={"../bbclogo.png"}/>
                <Typography className={classes.title} variant="h6"
                            noWrap>{Constants.clientTitle + this.clientVersionString}</Typography>
                <MediaQuery minWidth={600}>
                    <IconButton disabled={selectedView === VIEW_TYPE.VIEW_ACTIVITY}
                                onClick={(event) => {
                                    this.setState({
                                        showNotificationView: true,
                                        newLogEvents: 0
                                    });
                                }}>
                        <Badge
                            badgeContent={selectedView !== VIEW_TYPE.VIEW_ACTIVITY && !showNotificationView ? (newLogEvents ?? 0) : 0}
                            color={"secondary"}>
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton>
                    <IconButton onClick={() => {
                        this.showChangelog(false);
                    }}>
                        <InfoIcon/>
                    </IconButton>
                    <IconButton style={{marginRight: 8}} onClick={() => {
                        onToggleConsoleRequested();
                    }}>
                        <DevicesIcon/>
                    </IconButton>
                </MediaQuery>
                <MediaQuery maxWidth={600}>
                    <IconButton onClick={(event) => {
                        this.setState({
                            mobileMenuAnchorElement: event.currentTarget
                        });
                    }}>
                        <MoreIcon/>
                    </IconButton>
                </MediaQuery>
                <Badge anchorOrigin={{vertical: "bottom", horizontal: "right"}} color={"secondary"}
                       overlap={"rectangular"}
                       variant={"dot"} invisible={false}>
                    <Gravatar
                        edge={"end"}
                        email={currentUser?.username ?? ""}
                        size={40}
                        protocol={"https://"}
                        rating="pg"
                        default={"mp"}
                        style={{
                            cursor: "pointer",
                            borderRadius: 20,
                            transition: "all 250ms ease-in-out"
                        }}
                        onClick={(event) => {
                            this.setState({
                                accountMenuAnchorElement: event.currentTarget
                            });
                        }}
                    />
                </Badge>
                <Menu
                    open={this.state.accountMenuAnchorElement !== null}
                    onClose={() => (
                        this.setState({accountMenuAnchorElement: null})
                    )}
                    id="menu-appbar"
                    anchorEl={this.state.accountMenuAnchorElement}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem
                        disabled={true}>{currentUser ? currentUser.username : ""}</MenuItem>
                    <Badge
                        color={"primary"}
                        overlap={"rectangular"}
                        variant={"dot"} invisible={false}>
                        <MenuItem onClick={() => {
                            this.setState({
                                accountMenuAnchorElement: null
                            });

                            window.open("https://en.gravatar.com", "new");
                        }}>Change Avatar</MenuItem>
                    </Badge>
                    <MenuItem onClick={() => {
                        this.setState({
                            accountMenuAnchorElement: null,
                            changePasswordRequest: {}
                        });
                    }}>Change Password</MenuItem>
                    <MenuItem onClick={() => {
                        this.setState({
                            accountMenuAnchorElement: null,
                        });

                        onLogoutRequested();
                    }}>Log out</MenuItem>
                </Menu>
                <Menu
                    open={this.state.mobileMenuAnchorElement !== null}
                    onClose={() => (
                        this.setState({mobileMenuAnchorElement: null})
                    )}
                    id="menu-appbar"
                    anchorEl={this.state.mobileMenuAnchorElement}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem onClick={() => {
                        this.setState({
                            showNotificationView: true,
                            newLogEvents: 0,
                            mobileMenuAnchorElement: null
                        });
                    }}>Activity Log</MenuItem>
                    <MenuItem onClick={() => {
                        this.setState({
                            mobileMenuAnchorElement: null
                        });
                        this.showChangelog(false);
                    }}>Changelog</MenuItem>
                    <MenuItem onClick={() => {
                        this.setState({
                            mobileMenuAnchorElement: null
                        });
                        onToggleConsoleRequested();
                    }}>Console</MenuItem>
                </Menu>
            </Toolbar>
            <ModalDialog
                title={"Activity Log"}
                maxWidth={"md"}
                open={this.state.showNotificationView === true}
                hideCancelButton={true}
                disableEscapeKeyDown={false}
                disableBackdropClick={false}
                onCancel={() => this.setState({
                    showNotificationView: false,
                    newLogEvents: 0
                })}
                onOk={() => {
                    this.setState({
                        showNotificationView: false,
                        newLogEvents: 0
                    });
                }}>
                <DMSUserActivityView
                    persistFilters={false}
                    lightMode={true}
                    newLogEvents={this.state.newLogEvents}
                    dmsOnlineNodes={dmsOnlineNodes}
                    dmsNodes={dmsNodes}
                    logLine={logLine}
                    displaySnackbar={this.displaySnackbar}
                    classes={classes}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    closeSnackbar={this.props.closeSnackbar}/>
            </ModalDialog>
            {this.renderChangePasswordDialog()}
            {this.renderChangelogDialog()}
        </AppBar>;
    }

    private changePassword = async (changePasswordRequest: IChangePasswordRequest) => {
        const {dmsRestClient, currentUser} = this.props;

        try {
            if (changePasswordRequest?.validates) {
                const jwtToken = LocalStorageHelper.getAuthToken();

                if (jwtToken === null) {
                    console.log("missing jwt token");
                    return;
                }

                this.setState(prevState => ({
                    changePasswordRequest: {
                        ...prevState.changePasswordRequest,
                        isLoading: true
                    }
                }));

                let twoFactorToken = "";

                try {
                    twoFactorToken = await this.getTwoFactorTokenPromise("Changing the password requires two-factor verification");
                } catch (e) {
                    this.setState(prevState => ({
                        changePasswordRequest: {
                            ...prevState.changePasswordRequest,
                            isLoading: false
                        }
                    }));

                    return;
                }

                this.props.cancelTwoFactorRequest();

                await dmsRestClient.changePassword(jwtToken, twoFactorToken, currentUser!.username, changePasswordRequest.currentPassword!, changePasswordRequest.newPassword!);

                this.displaySnackbar("Successfully changed the password", "info");

                this.setState({
                    changePasswordRequest: undefined
                });
            }
        } catch (e) {
            this.displaySnackbar("Failed to change password. Error:" + e, "error");

            this.props.cancelTwoFactorRequest();

            this.setState(prevState => ({
                changePasswordRequest: {
                    ...prevState.changePasswordRequest,
                    isLoading: false
                }
            }));
        }
    };

    private renderChangelogDialog = (): ReactElement<Object> | undefined => {
        const {changelogContext} = this.state;

        if (!changelogContext) {
            return undefined;
        }

        let currentVersionString = Constants.clientVersion.major + "." + Constants.clientVersion.minor + "." + Constants.clientVersion.build;

        let lastVersionString;

        if (changelogContext && changelogContext.lastVersion) {
            lastVersionString = changelogContext.lastVersion.major + "." + changelogContext.lastVersion.minor + "." + changelogContext.lastVersion.build;
        }

        return <ModalDialog open={true}
                            title={Constants.clientTitle + " Changelog"}
                            buttonCancelDisabled={true}
                            disableEscapeKeyDown={false}
                            disableBackdropClick={false}
                            hideCancelButton={true}

                            onOk={() => {
                                this.setState({changelogContext: undefined});
                            }} onCancel={() => {
            this.setState({changelogContext: undefined});
        }}>
            {lastVersionString && currentVersionString ?
                <div>
                    {"Updated from version: "}
                    <Chip
                        deleteIcon={<ArrowRightIcon/>}
                        onDelete={() => {
                        }}
                        label={lastVersionString}/>
                    {" to "}
                    <Chip
                        label={currentVersionString}/>
                </div> : undefined}
            {!lastVersionString && currentVersionString ? <div>
                {"Current version: "}
                <Chip
                    variant={"outlined"}
                    size={"small"}
                    label={currentVersionString}/>
            </div> : undefined}
            <ReactMarkdown
            >
                {this.state.changelogContext?.changelogContents ?? ""}
            </ReactMarkdown>
        </ModalDialog>;
    };

    private renderChangePasswordDialog = () => {
        const {changePasswordRequest} = this.state;
        const {classes} = this.props;

        if (!changePasswordRequest) {
            return;
        }

        return (
            <ModalDialog open={this.state.changePasswordRequest !== undefined} title={"Change Password"}
                         buttonOkDisabled={!changePasswordRequest?.validates || changePasswordRequest.isLoading}
                         buttonCancelDisabled={changePasswordRequest?.isLoading}
                         buttonOkIsLoading={changePasswordRequest?.isLoading}
                         onOk={() => {
                             if (changePasswordRequest) {
                                 this.changePassword(changePasswordRequest);
                             }
                         }}
                         onCancel={() => {
                             this.setState({changePasswordRequest: undefined});
                         }}>
                <Container>
                    <TextField
                        variant={"standard"}
                        value={changePasswordRequest?.currentPassword ?? ""}
                        disabled={changePasswordRequest?.isLoading === true}
                        onChange={(event) => {
                            let newValue = "";
                            if (event && event.target && event.target.value) {
                                newValue = event.target.value;
                            }

                            this.setState(prevState => ({
                                changePasswordRequest: {
                                    ...prevState.changePasswordRequest,
                                    currentPassword: newValue
                                }
                            }), () => {
                                this.validateChangePasswordRequest(this.state.changePasswordRequest);
                            });
                        }}
                        className={classes.dialogTextField}
                        fullWidth
                        autoComplete="current-password"
                        type={"password"}
                        label={"Current Password"}/>
                    <TextField
                        variant={"standard"}
                        value={changePasswordRequest?.newPassword ?? ""}
                        disabled={changePasswordRequest?.isLoading === true}
                        onChange={(event) => {
                            let newValue = "";
                            if (event && event.target && event.target.value) {
                                newValue = event.target.value;
                            }

                            this.setState(prevState => ({
                                changePasswordRequest: {
                                    ...prevState.changePasswordRequest,
                                    newPassword: newValue
                                }
                            }), () => {
                                this.validateChangePasswordRequest(this.state.changePasswordRequest);
                            });
                        }}
                        className={classes.dialogTextField}
                        fullWidth
                        autoComplete="new-password"
                        type={"password"}
                        label={"New Password"}/>
                    <TextField
                        error={(changePasswordRequest?.passwordWeakness ?? 1) < 1}
                        helperText={(changePasswordRequest?.passwordWeakness ?? 1) < 1 ? "Weak password" : undefined}
                        variant={"standard"}
                        value={changePasswordRequest?.newPasswordConfirmation ?? ""}
                        disabled={changePasswordRequest?.isLoading === true}
                        onChange={(event) => {
                            let newValue = "";
                            if (event && event.target && event.target.value) {
                                newValue = event.target.value;
                            }

                            this.setState(prevState => ({
                                changePasswordRequest: {
                                    ...prevState.changePasswordRequest,
                                    newPasswordConfirmation: newValue
                                }
                            }), () => {
                                this.validateChangePasswordRequest(this.state.changePasswordRequest);
                            });
                        }}
                        className={classes.dialogTextField}
                        fullWidth
                        autoComplete="new-password"
                        type={"password"}
                        label={"Confirm Password"}/>
                </Container>

            </ModalDialog>);
    };

    private showChangelog = async (showIfVersionIsNewerThanPrevious = true) => {
        let displayChangelog = true;
        let lastVersion: IVersion | undefined;
        let currentVersion: IVersion = Constants.clientVersion;

        if (showIfVersionIsNewerThanPrevious) {
            lastVersion = LocalStorageHelper.getLastVersion();

            if (lastVersion) {
                displayChangelog = Util.compareVersions(Constants.clientVersion, lastVersion) > 0;
            }

            LocalStorageHelper.setLastVersion(Constants.clientVersion);
        }

        if (displayChangelog) {
            const response = await fetch("./changelog.md");
            const responseText = await response.text();

            this.setState({
                changelogContext: {
                    changelogContents: responseText,
                    lastVersion,
                    currentVersion
                }
            });
        }
    };

    private validateChangePasswordRequest = Util.debounce((changePasswordRequest: IChangePasswordRequest) => {
        let isValid: boolean;
        let passwordWeakness: number | undefined;

        if (!changePasswordRequest) {
            isValid = false;
        } else {
            isValid = (changePasswordRequest.newPassword ?? "").length > 7 && changePasswordRequest.newPassword === changePasswordRequest.newPasswordConfirmation;

            if (isValid) {
                const pwValidationResult = ValidationHelper.validatePassword(changePasswordRequest.newPassword!);
                isValid = pwValidationResult.valid;
                passwordWeakness = pwValidationResult.weakness;
            }
        }


        this.setState(prevState => ({
            changePasswordRequest: {
                ...prevState.changePasswordRequest,
                validates: isValid,
                passwordWeakness: passwordWeakness
            }
        }));
    }, 500);

    private displaySnackbar = (message: string, variant: VariantType = "info") => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {vertical: "top", horizontal: "center"}
        });
    };

    private getTwoFactorTokenPromise = (description?: string) => {
        return new Promise<string>((resolve, reject) => {
            this.props.twoFactorHandler({
                onGotToken: token => resolve(token),
                onCancelled: () => reject(),
                description
            });
        });
    };
}