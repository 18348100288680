import React from "react";
import Gravatar from "react-gravatar";
import {Chip} from "@mui/material";

interface IProps {
    chipSize?: "small" | "medium" | undefined;
    classes: any;
    username: string;
    isUserOnline?: (username: string) => boolean;
}

interface IState {

}

export default class DMSUserView extends React.Component<IProps, IState> {
    public render() {
        const {classes, chipSize} = this.props;

        const isUserOnline = this.props.isUserOnline ? this.props.isUserOnline(this.props.username) : false;

        const targetGravatarEmail = this.props.username === "dms_server" ? "" : this.props.username ?? "";

        return <Chip
            size={chipSize}
            avatar={
                <div style={{position: "relative"}}>
                    <Gravatar
                        email={targetGravatarEmail}
                        size={chipSize == "small" ? 16 : 24}
                        rating="pg"
                        default={"mp"}
                        protocol={"https://"}
                        className={classes.gravatarStyle}>
                    </Gravatar>
                    {this.props.isUserOnline !== undefined ? <div
                        className={classes.userConnectionIndicator}
                        style={{
                            background: isUserOnline ? "rgb(104,180,53)" : "rgb(238,40,40)",
                            top: chipSize == "small" ? "9px" : "12px",
                            left: chipSize == "small" ? "9px" : "13px",
                        }}/> : undefined
                    }
                </div>
            }
            label={this.props.username}
        />;
    }
}