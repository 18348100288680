import React from "react";
import {createStyles, withStyles} from "@mui/styles";

const styles = () => createStyles({
    console: {
        maxHeight: "40vh",
        background: "black",
        flex: 1,
        color: "#00FF00",
        fontSize: "13px",
        overflowX: "hidden",
        overflowY: "scroll",
        padding: "8px",
        /*border: "dotted white 1px",*/
        marginRight: "4px",
        lineHeight: 1.25,
        textRendering: "optimizeLegibility",
        fontFamily: "Menlo",
        fontWeight: "bold",
        borderTop: "0.5px solid rgb(126, 126, 126)"
    },
    inputContainer: {
        background: "black",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        paddingLeft: "8px !important",
    },
    input: {
        fontFamily: "Menlo",
        fontWeight: "bold",
        color: "#00FF00",
        fontSize: "13px",
        background: "black",
        width: "100%",
        outline: "none",
        flex: 1
    },
    line: {
        whiteSpace: "pre-wrap",
    },
    lineError: {
        color: "red",
    },
    lineWarn: {
        color: "orange",
    },
});

interface IProps {
    classes: any;
    logItems: string[];
    style?: any;
    caretHeader?: string;
    onCommand?: (cmd: string) => void;
}

// tslint:disable-next-line: no-empty-interface
interface IState {
    cmd: string;
    cmdHistory: string[];
    cmdHistoryPosition: number;
}

class Console extends React.Component<IProps, IState> {
    state: IState = {
        cmd: "",
        cmdHistory: [],
        cmdHistoryPosition: 0
    };

    private ref: any;

    public render() {
        const {classes} = this.props;

        this.scrollToBottom();

        return (
            <div onKeyDown={(event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === "k") {
                    while (this.props.logItems.length > 0) {
                        this.props.logItems.pop();
                    }

                    this.forceUpdate();
                } else if (event.key === "ArrowUp") {
                    let {cmdHistoryPosition, cmdHistory} = this.state;

                    if (cmdHistoryPosition + 1 < cmdHistory.length) {
                        cmdHistoryPosition++;
                    } else {
                        cmdHistoryPosition = 0;
                    }

                    this.setState({cmdHistoryPosition, cmd: cmdHistory[cmdHistoryPosition]});
                } else if (event.key === "ArrowDown") {
                    let {cmdHistoryPosition, cmdHistory} = this.state;

                    if (cmdHistoryPosition - 1 > -1) {
                        cmdHistoryPosition--;
                    } else {
                        cmdHistoryPosition = cmdHistory.length;
                    }

                    this.setState({cmdHistoryPosition, cmd: cmdHistory[cmdHistoryPosition]});
                }
            }} style={this.props.style} className={classes.console} ref={(el) => this.ref = el}>
                {
                    this.props.logItems.map((logItem, i) =>
                        <div id="line" className={classes.line} key={i.toString()}>{logItem}<br/></div>,
                    )
                }
                <br/>
                {this.props.onCommand ? <div className={classes.inputContainer}>
                    {this.props.caretHeader ?? "~ % "}
                    <input
                        onChange={(e) => {
                            this.setState({cmd: e.target.value});
                        }}
                        value={this.state.cmd} onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            this.props.onCommand!(this.state.cmd);
                            const {cmdHistory} = this.state;
                            cmdHistory.push(this.state.cmd);

                            this.setState({cmd: "", cmdHistoryPosition: cmdHistory.length}, this.scrollToBottom);
                            this.scrollToBottom();
                        }
                    }} autoFocus={true} className={classes.input} type="text" id="consoleInput"/>
                </div> : undefined}
            </div>
        );
    }

    private scrollToBottom = () => {
        if (this.ref) {
            this.ref.scrollTo({
                top: this.ref.scrollHeight,
                left: 0,
                behavior: 'smooth'
            });
        }
    };
}

export default withStyles(styles, {withTheme: true})(Console);
