import React from "react";
import {
    Menu,
    MenuItem,
    Paper,
    TableContainer
} from "@mui/material";
import {VariantType, WithSnackbarProps} from "notistack";
import {
    Util,
    IDMSFile, DMSRESTApiClient
} from "dms_commons";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModalDialog from "./ModalDialog";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import MaterialTable, {Column} from "@material-table/core";

interface IProps extends WithSnackbarProps {
    classes: any;
    dmsFiles: IDMSFile[];
    isLoading: boolean;
    fullTable: boolean;
    onRowSelected: (f: IDMSFile, index: number) => void;
    onLoadDataRequested: () => void;
    dmsRestClient?: DMSRESTApiClient;
}

interface IState {
    deletionTargetFile?: IDMSFile;
    isDeletingFileInProgress: boolean;
    tableColumns: Column<IDMSFile>[];
    selectedFile?: IDMSFile;
    fileMenuAnchorElement?: any;
}

export default class DMSFilesTable extends React.Component<IProps, IState> {
    public state: IState = {
        isDeletingFileInProgress: false,
        tableColumns: []
    };

    public componentDidMount() {
        this.initTableColumns();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.dmsFiles === prevProps.dmsFiles) {
            return;
        }

        this.initTableColumns();
    }

    private initTableColumns = () => {
        const tableColumns: Column<IDMSFile>[] = [
            {
                title: "Filename",
                field: "filename",
                filtering: false
            },
            {
                title: "File Size",
                field: "fileSize",
                type: "numeric",
                filtering: false,
                render: rowData => {
                    return <p>{Util.readableFileSizeString(rowData.fileSize)}</p>;
                }
            },
            {
                title: "Created Date",
                field: "createdDate",
                type: "date",
                align: "right",
                render: rowData => {
                    return <p>{Util.relativeDateTimeStringFromDBTimestamp(rowData.createdDate)}</p>;
                }
            },
            {
                title: "Modified Date",
                field: "modifiedDate",
                type: "date",
                align: "right",
                defaultSort: "desc",
                render: rowData => {
                    return <p>{Util.relativeDateTimeStringFromDBTimestamp(rowData.modifiedDate)}</p>;
                }
            },
            {
                title: "Content Type",
                field: "contentType",
                filtering: false,
                render: rowData => {
                    return <p>{rowData.contentType ?? "application/octet-stream"}</p>;
                }
            },
            {
                title: "CRC32",
                field: "crc32",
                filtering: false,
                render: rowData => {
                    if (rowData.crc32) {
                        const hexString = this.base64ToHex(rowData.crc32);
                        return <p>{hexString}</p>;
                    } else {
                        return <p>{"N/A"}</p>;
                    }
                }
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    return (<MoreHorizIcon style={{cursor: "pointer"}}
                                           onClick={(event) => {
                                               this.setState({
                                                   fileMenuAnchorElement: event.currentTarget,
                                                   selectedFile: rowData
                                               });
                                           }}>
                    </MoreHorizIcon>);
                }
            }
        ];

        tableColumns.forEach(c => {
            c.width = 1;
        });

        this.setState({tableColumns});
    };

    public render() {
        const {fullTable, isLoading, dmsFiles, onRowSelected} = this.props;
        const {deletionTargetFile, isDeletingFileInProgress, tableColumns} = this.state;

        return <TableContainer {...fullTable ? {component: Paper} : undefined}>
            {
                fullTable ? <ModalDialog open={deletionTargetFile !== undefined}
                                         buttonOkIsLoading={isDeletingFileInProgress}
                                         buttonCancelDisabled={isDeletingFileInProgress}
                                         buttonOkDisabled={isDeletingFileInProgress}
                                         title={`Are you sure you want to delete '${deletionTargetFile?.filename}'`}
                                         onOk={() => {
                                             this.onDeleteFileRequested(deletionTargetFile!);
                                         }} onCancel={() => {
                    this.setState({deletionTargetFile: undefined});
                }}>
                    {"This action is permanent and cannot be undone!"}
                </ModalDialog> : undefined
            }
            <MaterialTable
                isLoading={isLoading}
                title="Files"
                columns={tableColumns}
                data={dmsFiles}
                options={{
                    headerStyle: {
                        backgroundColor: "rgb(65,65,65)",
                    },
                    idSynonym: "filename",
                    showTitle: true,
                    draggable: false,
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 100],
                    loadingType: "overlay",
                    filtering: false,
                    emptyRowsWhenPaging: false,
                    padding: "dense"
                }}
                actions={[
                    {
                        icon: 'refresh',
                        hidden: !fullTable,
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: this.props.onLoadDataRequested
                    },
                ]}
                onRowClick={(event, rowData, toggleDetailPanel) => {
                    if (rowData) {
                        onRowSelected(rowData, dmsFiles.indexOf(rowData));
                    }
                }}
            />
            <Menu
                open={this.state.fileMenuAnchorElement != null}
                onClose={() => {
                    this.setState({fileMenuAnchorElement: undefined});
                }}
                id="menu-cell"
                anchorEl={this.state.fileMenuAnchorElement}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted={false}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem
                    onClick={() => {
                        this.setState({
                            fileMenuAnchorElement: undefined,
                            deletionTargetFile: this.state.selectedFile
                        });
                    }}>Delete File</MenuItem>
                <MenuItem
                    onClick={() => {
                        this.setState({fileMenuAnchorElement: undefined});
                        window.open(this.state.selectedFile!.publicLink);
                    }}>Download File</MenuItem>
                <MenuItem
                    onClick={() => {
                        this.setState({fileMenuAnchorElement: undefined});
                        navigator.clipboard.writeText(this.state.selectedFile!.publicLink as string);
                        this.displaySnackbar("Copied to clipboard", "success");
                    }}>Copy Link</MenuItem>
            </Menu>
        </TableContainer>;
    }

    private onDeleteFileRequested = async (file: IDMSFile) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isDeletingFileInProgress: true});

        try {
            await this.props.dmsRestClient!.deleteFileFromBucket(jwtToken, file.filename);
            await this.props.onLoadDataRequested();

            this.setState({isDeletingFileInProgress: false, deletionTargetFile: undefined});
        } catch (error) {
            this.setState({isDeletingFileInProgress: false, deletionTargetFile: undefined});
            this.displaySnackbar(`Could not delete file: ${file.filename}: ${error}`, "error");
        }
    };

    private displaySnackbar = (message: string, variant: VariantType = "info") => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {vertical: "top", horizontal: "center"}
        });
    };

    private base64ToHex(base64: string) {
        const raw = atob(base64);
        let hexString = '';
        for (let i = 0; i < raw.length; i++) {
            const hex = raw.charCodeAt(i).toString(16).padStart(2, '0');
            hexString += hex;
        }
        return hexString.toUpperCase(); // Prepend '0x' and return in upper case
    }
}
