import {VIEW_TYPE} from "../enums/VIEW_TYPE";
import {IVersion} from "dms_commons";

const TOKEN_KEY = "token";
const CURRENT_VIEW_KEY = "current_view";
const CONSOLE_ENABLED_KEY = "console_enabled";
const LAST_VERSION_KEY = "installed_version";
const SERVER_STATS_EXPANDED = "server_stats_expanded";
const DASH_PANEL_EXPANDED = "dash_panel_expanded_";
const DASH_SORTING = "dash_sorting";
const DASH_FLAT_UI = "dash_flat_ui";
const DASH_ENABLED_LOCATIONS = "dash_enabled_locations";
const DASH_FILTER_LOCATIONS = "dash_filter_locations";
const USER_ONLINE_NOTIFICATIONS_ENABLED = "user_online_notifications_enabled";
const PORT_FORWARDING_NOTIFICATIONS_ENABLED = "port_forwarding_notifications_enabled";

export default class LocalStorageHelper {
    public static getAuthToken(): string | null {
        return localStorage.getItem(TOKEN_KEY);
    }

    public static setAuthToken(token: string | null) {
        if (!token) {
            localStorage.removeItem(TOKEN_KEY);
            return;
        }

        localStorage.setItem(TOKEN_KEY, token);
    }

    public static getCurrentView(): VIEW_TYPE {
        const selectedView = localStorage.getItem(CURRENT_VIEW_KEY);

        const n = Number(selectedView);

        return n as VIEW_TYPE;
    }

    public static setCurrentView(currentView: VIEW_TYPE | null) {
        if (currentView === null) {
            localStorage.removeItem(CURRENT_VIEW_KEY);
            return;
        }

        localStorage.setItem(CURRENT_VIEW_KEY, currentView.toString());
    }

    public static getServerStatsExpanded(): boolean {
        const serverStatsExpanded = localStorage.getItem(SERVER_STATS_EXPANDED);

        return serverStatsExpanded === "true";
    }

    public static setServerStatsExpanded(enabled: boolean) {
        localStorage.setItem(SERVER_STATS_EXPANDED, enabled.toString());
    }

    public static getDashPanelExpanded(panel: string): boolean | null {
        const isExpanded = localStorage.getItem(DASH_PANEL_EXPANDED + panel);

        return isExpanded === null ? null : isExpanded === "true";
    }

    public static setDashPanelExpanded(panel: string, enabled: boolean) {
        localStorage.setItem(DASH_PANEL_EXPANDED + panel, enabled.toString());
    }

    public static getDashDefaultSorting() {
        const defaultSorting = localStorage.getItem(DASH_SORTING);

        return defaultSorting ?? "default";
    }

    public static setDashDefaultSorting(sorting: "default" | "alpha") {
        localStorage.setItem(DASH_SORTING, sorting);
    }

    public static getDashUseFlatUI(): boolean {
        return localStorage.getItem(DASH_FLAT_UI) === "true";
    }

    public static setDashUseFlatUI(useFlatUI: boolean) {
        localStorage.setItem(DASH_FLAT_UI, useFlatUI.toString());
    }

    public static getDashEnabledLocations(): string[] {
        const enabledLocationsString = localStorage.getItem(DASH_ENABLED_LOCATIONS);
        return (enabledLocationsString ?? "").split(",");
    }

    public static setDashEnabledLocations(enabledLocations: string[]) {
        localStorage.setItem(DASH_ENABLED_LOCATIONS, enabledLocations.join(","));
    }

    public static getDashFilterLocations(): boolean {
        return localStorage.getItem(DASH_FILTER_LOCATIONS) === "true";
    }

    public static setDashFilterLocations(val: boolean) {
        localStorage.setItem(DASH_FILTER_LOCATIONS, val.toString());
    }

    public static getConsoleEnabled(): boolean {
        const consoleEnabled = localStorage.getItem(CONSOLE_ENABLED_KEY);

        return consoleEnabled === "true";
    }

    public static setConsoleEnabled(enabled: boolean) {
        localStorage.setItem(CONSOLE_ENABLED_KEY, enabled.toString());
    }

    public static getLastVersion(): IVersion | undefined {
        const lastVersionJson = localStorage.getItem(LAST_VERSION_KEY);

        if (lastVersionJson) {
            return JSON.parse(lastVersionJson) as IVersion;
        }
    }

    public static setLastVersion(version: IVersion) {
        localStorage.setItem(LAST_VERSION_KEY, JSON.stringify(version));
    }

    public static getUserOnlineNotificationsEnabled = () => {
        const enabled = localStorage.getItem(USER_ONLINE_NOTIFICATIONS_ENABLED);

        return (enabled ?? "true") === "true";
    };

    public static setUserOnlineNotificationsEnabled(enabled: boolean) {
        localStorage.setItem(USER_ONLINE_NOTIFICATIONS_ENABLED, enabled.toString());
    }


    public static getPortForwardingNotificationsEnabled = () => {
        const enabled = localStorage.getItem(PORT_FORWARDING_NOTIFICATIONS_ENABLED);

        return (enabled ?? "true") === "true";
    };

    public static setPortForwardingNotificationsEnabled(enabled: boolean) {
        localStorage.setItem(PORT_FORWARDING_NOTIFICATIONS_ENABLED, enabled.toString());
    }
}
