import {Card, Avatar, Container, CssBaseline, Typography} from "@mui/material";
import {createStyles, withStyles} from '@mui/styles';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import React from "react";
import {Theme} from "@mui/material/styles";

const styles = (theme: Theme) => createStyles({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    header: {
        margin: 8,
        textAlign: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
});

interface IProps {
    classes: any;
}

// tslint:disable-next-line: no-empty-interface
interface IState {

}

class NotFound extends React.Component<IProps, IState> {
    public render() {
        const {classes} = this.props;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Card>
                        <Typography className={classes.header} component="h1" variant="h5">
                            The requested resource cannot be found
                        </Typography>
                    </Card>
                </div>
            </Container>
        );
    }
}

export default withStyles(styles, {withTheme: true})(NotFound);
