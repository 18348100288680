import {ENV_TYPE} from "../Constants";

export default class EndpointHelper {
    public static getDmsScheme = (env: ENV_TYPE): "http" | "https" => {
        switch (env) {
            case "test":
            case "prod":
            case "localhost_https":
                return "https";
            default:
            case "localhost_http":
                return "http";

        }
    }

    public static getDmsHostname = (env: ENV_TYPE): string => {
        switch (env) {
            case "test":
                return "test.bbcdms.app";
            case "prod":
                return "bbcdms.app";
            case "localhost_http":
            case "localhost_https":
                return "localhost";
        }
    }

    public static getDmsPortNumber = (env: ENV_TYPE): number => {
        switch (env) {
            case "test":
            case "prod":
            case "localhost_https":
                return 443;
            case "localhost_http":
                return 8080;
        }
    }
}