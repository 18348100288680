import {
    IBitbucketConfig,
    IDBNode,
    IDMSSoftwareBundle,
    IDMSLogFile,
    IBBCBridgeAction,
    IDMSExecuteBridgeActionResult
} from "dms_commons";

export enum DMSNodeManagingSessionState {
    READY,
    GETTING_NODE_SOFTWARE,
    GOT_NODE_SOFTWARE,
    SELECTING_SOFTWARE_TO_UPDATE,
    SELECTING_UPDATE_PAYLOAD,
    UPDATING_SOFTWARE,
    UPDATING_SOFTWARE_SEQUENTIALLY,
    GETTING_LATEST_CONFIG_FROM_BITBUCKET,
    GOT_CONFIG_FROM_BITBUCKET,
    COPY_CONFIG_FROM_NODE,
    UPDATING_CONFIG,
    NODE_RESTARTING_SOFTWARE,
    NODE_DOWNLOADING_SOFTWARE,
    NODE_INSTALLING_SOFTWARE,
    NODE_UPDATE_SUCCEEDED,
    NODE_UPDATE_FAILED,
    NODE_UPDATE_CANCELLED,
    LISTING_NODE_LOGS,
    LISTED_NODE_LOGS,
    GETTING_NODE_LOG,
    GOT_NODE_LOG,
    LISTING_NODE_BRIDGE_ACTIONS,
    LISTED_NODE_BRIDGE_ACTIONS,
    CONFIGURE_NODE_BRIDGE_ACTION,
    EXECUTING_NODE_BRIDGE_ACTION,
    EXECUTED_NODE_BRIDGE_ACTION,
    EXECUTED_NODE_BRIDGE_ACTION_WITH_RESULT,
    EXECUTE_NODE_BRIDGE_ACTION_FAILED,
    BATCH_GETTING_LATEST_CONFIG_FROM_BITBUCKET,
    BATCH_GOT_CONFIG_FROM_BITBUCKET,
    UPDATING_CONFIG_SEQUENTIALLY
}

export default interface IDMSNodeManagingSession {
    targetNode?: IDBNode;
    sessionMode: "software" | "batch-software" | "config" | "restart" | "batch-restart" | "logs" | "batch-config" | "actions";
    state: DMSNodeManagingSessionState;
    nodeSoftware?: IDMSSoftwareBundle[];
    selectedSoftware?: IDMSSoftwareBundle;
    selectedBitbucketConfig?: IBitbucketConfig;
    selectedAction?: IBBCBridgeAction;
    actionResult?: IDMSExecuteBridgeActionResult;
    ignoreSoftwareVersion?: boolean;

    copyFromNode?: IDBNode;

    nodeLogsList?: IDMSLogFile[];
    nodeLog?: IDMSLogFile;
    nodeBridgeActions?: IBBCBridgeAction[];

    batchConfigs?: IBitbucketConfig[];
    batchTargetNodes?: IDBNode[];
    batchUpdateProgress?: number;
    batchUpdateSequentially?: boolean;
    batchUpdateContext?: {
        [uid: string]: { software?: IDMSSoftwareBundle[], offline?: boolean, updating?: boolean, updated?: boolean }
    };
    oldConfig?: string;
}
