import React from "react";
import {createStyles, withStyles} from "@mui/styles";
import {green} from "@mui/material/colors";
import {Button, CircularProgress} from "@mui/material";

const styles = () => createStyles({
    wrapper: {
        margin: 2,
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
});

interface IProps {
    classes: any;
    style?: any;
    isLoading?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    children: any;
}

// tslint:disable-next-line: no-empty-interface
interface IState {

}

class CustomButton extends React.Component<IProps, IState> {
    private ref: any;

    public render() {
        const {classes, color, isLoading, disabled, onClick} = this.props;

        const buttonClassname = "buttonSuccess";

        return (<div className={classes.wrapper}>
                <Button
                    variant={isLoading ? "outlined" : "text"}
                    color={color}
                    className={buttonClassname}
                    disabled={disabled || isLoading}
                    onClick={onClick}
                >
                    {this.props.children}
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(CustomButton);
