export enum VIEW_TYPE {
    VIEW_NODES,
    VIEW_API,
    VIEW_FILES,
    VIEW_USERS,
    VIEW_PORT_FORWARDING,
    VIEW_ACTIVITY,
    VIEW_HEALTH,
    VIEW_SETTINGS,
    VIEW_DASH
}
